"use client";

import HeaderBanner from "@/components/HeaderBanner";
import Jokes from "@/components/Jokes";
import LogoAnimation from "@/components/LogoAnimation";
import StarsAndSparkles from "@/components/Sparkle";
import BrowserCamera from "@/components/browser-camera";
import IconCloudUpload from "@/components/icon/CloudUploadIcon";
import { useGenericMutation } from "@/hooks/api/useGenericMutation";
import { useFingerprint } from "@/hooks/use-fingerprint";
import { usePlayGameStore } from "@/store/play-game";
import { Vendor } from "@/types/vendor";
import { BaseUrls } from "@/utils/baseUrls";
import { Button, Divider, LoadingOverlay, Modal, PinInput } from "@mantine/core";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import Image from "next/image";
import { useRouter } from "next/navigation";
import { useState } from "react";

export default function Home() {
  const { fingerprint, sessionId } = useFingerprint();
  const [image, setImage] = useState<any>(null);
  const [opened, { open, close }] = useDisclosure(false);
  const matches = useMediaQuery("(max-width: 56.25em)");
  const router = useRouter();
  const [loading, setLoading] = useState(false);
  const [isCode, setIsCode] = useState(false);

  const [isCameraOpen, setIsCameraOpen] = useState(false);

  const parse = useGenericMutation<
    Vendor,
    {
      encodedImage: string;
      fingerprint: string;
      sessionId: string;
    }
  >({
    baseUrl: BaseUrls.PLAYE,
    isFormData: false,
    endpoint: "/parse-document-for-vendor",
    onSuccess: (result) => {
      if (!result.id) {
        close();
        notifications.show({
          title: "Store Not Found",
          message: "",
          color: "red",
        });
        return;
      }

      router.push("/store/" + result.id);
    },
    onError: (error) => {
      close();
      notifications.show({
        title: "Store Not Found",
        message: "",
        color: "red",
      });
    },
  });

  // var parseImage = async (base64Image: string) => {
  //   var bodyFormData = new FormData();

  //   const base64ToFile = (base64: string, filename: string) => {
  //     var arr = base64.split(","),
  //       mimeMatch = arr[0].match(/:(.*?);/),
  //       mime = mimeMatch ? mimeMatch[1] : "",
  //       bstr = atob(arr[1]),
  //       n = bstr.length,
  //       u8arr = new Uint8Array(n);

  //     while (n--) {
  //       u8arr[n] = bstr.charCodeAt(n);
  //     }

  //     return new File([u8arr], filename, { type: mime });
  //   };

  //   bodyFormData.append("Data", base64ToFile(base64Image, randomInt(1000000000000, 9000000000000).toString()));
  //   await parse.mutateAsync(bodyFormData);
  // };

  const { playGame, setPlayGame } = usePlayGameStore();

  return (
    <div
      style={{
        height: "100svh",
      }}
      className="relative flex flex-col bg-[#0A000B]"
    >
      {isCameraOpen && (
        <BrowserCamera
          onCapture={async (image) => {
            setImage(image);
            setIsCameraOpen(false);
            open();
            // parseImage(image);
            if (sessionId && fingerprint) {
              setPlayGame({
                image: typeof image === "string" ? image : "",
                fingerprint,
                sessionId,
              });

              await parse.mutateAsync({
                encodedImage: typeof image === "string" ? image.replace(/^data:image\/(png|jpeg|jpg);base64,/, "") : "",
                fingerprint,
                sessionId,
              });
            }
          }}
          onClosed={() => setIsCameraOpen(false)}
        />
      )}
      <LoadingOverlay
        visible={loading}
        loaderProps={{
          color: "rgba(0, 0, 0, 1)",
          size: "xl",
          type: "dots",
        }}
      />
      <div className="absolute  top-3 left-1/2 -translate-x-1/2 z-40 w-full px-2 md:px-4 max-w-6xl ">
        <HeaderBanner />
      </div>
      <div className="md:h-1/3 z-20 h-[46%] relative bg-[#0A000B] overflow-hidden object-cover  object-center w-full ">
        <LogoAnimation />
        <div className="top-0 h-full w-full absolute z-10 bg-gradient-to-b  from-transparent to-[#0A000B] "></div>H
      </div>
      <div className="md:h-2/3 h-[54%] max-w-2xl mx-auto flex items-center md:justify-start justify-between  flex-col  px-4 py-2">
        {!isCode ? (
          <div className="w-full">
            <div onClick={() => setIsCameraOpen(true)} className="flex items-center flex-col">
              <IconCloudUpload />
              <p className="text-center text-gray-300 md:text-xl text-lg">Tap here</p>
            </div>

            <p className="text-blue-800 cursor-pointer text-center w-full mt-1" onClick={() => setIsCode(!isCode)}>
              Receipt not scanning ?
            </p>
          </div>
        ) : (
          <div className="mt-10">
            <PinInput
              type="number"
              inputMode="numeric"
              length={6}
              classNames={{
                input: "bg-transparent border-t-0 border-x-0 border-b-2 border-white border-solid rounded-none",
                pinInput: "",
                root: "",
              }}
              onComplete={async (code) => {
                setLoading(true);

                var vendor = await fetch(`${BaseUrls.PLAYE}/vendor/get-vendor-by-code?code=${code}`, {
                  method: "GET",
                  headers: {
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                  },
                })
                  .then((res) => res.json())
                  .catch(() => {
                    setLoading(false);
                    notifications.show({
                      title: "Store Not Found",
                      message: "",
                      color: "red",
                    });
                    return;
                  });

                if (vendor.id) {
                  router.push("/store/" + vendor.id);
                }
              }}
            />
            <p className="text-center mt-10">Enter Store Code</p>
            <p className=" text-blue-800 text-center cursor-pointer mt-1" onClick={() => setIsCode(!isCode)}>
              Capture Image ?
            </p>{" "}
          </div>
        )}

        {/* <p className="text-blue-800 md:text-sm text-xs font-medium text-center md:pt-20">
          By uploading you receipt , you agree to the playe terms of use and privacy policy
        </p> */}

        <p className="md:pt-10 font-sans text-xs text-blue-200/60">
          By creating an account, you agree to the <text className="text-blue-600 underline">Terms of Service</text>.
          For more information about <text className="text-blue-600 underline">Playe's privacy practices</text>, see the
          Playe Privacy Statement. We'll occasionally send you account-related emails.
        </p>
      </div>

      <Modal
        withCloseButton={false}
        opened={opened}
        onClose={close}
        fullScreen={matches}
        radius={0}
        transitionProps={{ transition: "fade", duration: 200 }}
      >
        <div className="inline-flex flex-col justify-center items-center w-full">
          <div className="w-full flex justify-end items-end">
            <Button color="blue" variant="subtle" onClick={close}>
              Skip
            </Button>
          </div>

          <div className="rounded-full relative">
            <Image
              className="object-cover  overflow-hidden rounded-2xl object-center   h-[400px] w-[220px]"
              alt=""
              src={image}
              width={1920}
              height={1080}
            />

            {/* <div className="absolute h-[100px] w-[140%]  card">
              <div className="halftone"></div>
            </div> */}
            <StarsAndSparkles />
          </div>

          <div className="text-center mt-10">
            <p>Have a laugh while we scan your receipt</p>
            <Divider className="my-4" />

            <Jokes />
          </div>
        </div>
      </Modal>
    </div>
  );
}

const randomInt = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};
