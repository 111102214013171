import axios from "axios";
import http from "http";
import https from "https";

const createAxiosInstance = (baseURL: string) => {
  const instance = axios.create({
    baseURL,
    httpAgent: new http.Agent({ keepAlive: true }),
    httpsAgent: new https.Agent({ keepAlive: true })
  });

  instance.interceptors.request.use((config) => {


    return config;
  });

  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  return instance;
};

export default createAxiosInstance;
