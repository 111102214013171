"use client";

import { Button } from "@/components/ui/button";
import { IconX } from "@tabler/icons-react";
import React, { useCallback, useEffect, useRef, useState } from "react";

interface IBrowserCameraProps {
  onCapture: (image: string | ImageData) => void;
  onClosed: () => void;
}

const BrowserCamera: React.FC<IBrowserCameraProps> = ({ onCapture, onClosed }) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [photo, setPhoto] = useState<string | null>(null);
  const [cameraActive, setCameraActive] = useState(false);

  const startCamera = useCallback(async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: { facingMode: "environment" },
      });
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        setCameraActive(true);
      }
    } catch (err) {
      console.error("Error accessing the camera:", err);
    }
  }, []);

  const stopCamera = useCallback(() => {
    if (videoRef.current && videoRef.current.srcObject) {
      const tracks = (videoRef.current.srcObject as MediaStream).getTracks();
      tracks.forEach((track) => track.stop());
      setCameraActive(false);
    }
  }, []);

  const capturePhoto = useCallback(() => {
    if (videoRef.current && canvasRef.current) {
      const context = canvasRef.current.getContext("2d");
      if (context) {
        const { videoWidth, videoHeight } = videoRef.current;
        canvasRef.current.width = videoWidth;
        canvasRef.current.height = videoHeight;

        // Capture the image without mirroring
        context.drawImage(videoRef.current, 0, 0, videoWidth, videoHeight);

        const photoDataUrl = canvasRef.current.toDataURL("image/jpeg");
        setPhoto(photoDataUrl);
        stopCamera();
      }
    }
  }, [stopCamera]);

  const retakePhoto = useCallback(() => {
    setPhoto(null);
    startCamera();
  }, [startCamera]);

  const handleUsePhoto = useCallback(() => {
    if (photo) {
      onCapture(photo);
      onClosed();
    }
  }, [photo, onCapture, onClosed]);

  const handleClose = useCallback(() => {
    stopCamera();
    onClosed();
  }, [stopCamera, onClosed]);

  useEffect(() => {
    startCamera();
    return () => {
      stopCamera();
    };
  }, [startCamera, stopCamera]);

  return (
    <div className="z-[99999999] fixed inset-0 bg-black text-white flex flex-col">
      <div className="absolute top-4 right-4 z-10">
        <Button
          onClick={handleClose}
          variant="ghost"
          className="flex items-center justify-center w-10 h-10 p-0 hover:bg-gray-700/50 hover:text-white text-white rounded-full bg-gray-950/50"
        >
          <IconX size={24} />
        </Button>
      </div>
      <div className="relative flex-grow">
        {!photo && <video ref={videoRef} autoPlay playsInline muted className="w-full h-full object-cover" />}
        {photo && <img src={photo} alt="Captured photo" className="w-full h-full object-cover" />}
      </div>
      <div className="absolute bottom-0 left-0 right-0 p-4 bg-black bg-opacity-50 flex justify-center items-center">
        {!photo ? (
          <Button onClick={capturePhoto} className="rounded-full w-16 h-16 bg-white p-2" disabled={!cameraActive}>
            <div className="rounded-full w-full h-full border-2 border-black"></div>
          </Button>
        ) : (
          <div className="flex justify-between w-full">
            <Button onClick={retakePhoto} variant="link" className="text-white">
              Retake
            </Button>
            <Button onClick={handleUsePhoto} variant="link" className="text-white">
              Use Photo
            </Button>
          </div>
        )}
      </div>
      <canvas ref={canvasRef} style={{ display: "none" }} />
    </div>
  );
};

export default BrowserCamera;
